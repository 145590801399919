<template>
  <b-modal
    @shown="onShown()"
    :hide-header="true"
    :hide-footer="true"
    content-class="modal-blue"
    id="key-vocabulary-modal"
    centered>

  </b-modal>
</template>

<script>
  export default {
    name: 'key-vocabulary-modal',

    props: ['unit'],

    methods: {
      onShown() {
        this.loadKeyVocabulary();
      },

      onHide() {
        this.$emit('hide');
      },

      loadKeyVocabulary() {
        alert('todo load key vocabulary');
      }
    }
  };
</script>

<style scoped>

</style>
