<template>
  
  <div class="p-4">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div  class="unit-badge">
        <router-link :to="unit.isOpen==1?'/lab/' + unit.unitID:'#'" class="text-white">Unit {{ unit.quiz }}  </router-link>
      </div>
      <span class="ml-4 f-heading-bold">{{ helpers.getLocalizedField(unit, 'subject') }}</span>
      <div class="mr-3">
<!--        <a href="#" @click="handleKeyVocabularyClick">{{ $t('lab.key-vocabulary') }}</a>-->
      </div>

    </div>
    <div class="col-12 info bg-light px-5 py-4 b-20 shadow-1">
      <div class="d-flex bg-light-blue b-20 mb-3 py-2">
        <div class="col-3">{{ $t('lab.date') }}:</div>
        <div class="col-9">{{ unit.finished_at ? unit.finished_at.substring(0, 19) : '-' }}</div>
      </div>
      <div class="d-flex bg-light-blue b-20 py-2">
        <div class="col-3">{{ $t('lab.score') }}:</div>
        <div class="col-9">{{ unit.point ? parseFloat(unit.point) : '-' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'unit-item',

    props: ['unit'],

    methods: {
      handleKeyVocabularyClick() {
        this.$emit('key-vocabulary-click', this.unit);
      }
    },

   
  };
</script>

<style scoped>
  .unit-badge{
    display: inline-flex;
    font-size:1.87rem;
    line-height: 1.5rem;
    padding: .85rem 1.5rem;
    background-color: rgba(183, 0, 6,.85);
    border-radius: 7px;
  }
  .unit-badge:hover{
    text-decoration: underline;
  }
  .info{
    min-width: 42rem;
    font-size: 1.25rem;
  }
</style>
