<template>
    <div class="container-fluid">
        <div class="row">
            <sidebar></sidebar>
            <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>
                <section class="row px-0 mx-0 px-xl-5 mx-xl-5 mb-5">
                  <div class="col-md-12 col-lg-6">
                    <div class="bg-light b-30 p-5 shadow-1">
                      <h4>{{ $t('lab.online-dictionaries') }}:</h4>
                      <ul class="list">
                        <li><a target="_blank" href="https://www.dictionary.cambridge.org">www.dictionary.cambridge.org</a></li>
                        <li><a target="_blank" href="https://www.thefreedictionary.com">www.thefreedictionary.com</a></li>
                        <li><a target="_blank" href="https://translate.google.com">www.translate.google.com</a></li>
                        <li><a target="_blank" href="https://www.translate.ge">www.translate.ge</a></li>
                        <li><a target="_blank" href="http://translate.voov.me/">translate.voov.me</a></li>
                      </ul>
                    </div>
                  </div>
                  <div v-if="note" class="col-md-12 col-lg-6 mt-4 mt-lg-0">
                    <div class="bg-light b-30 p-5 shadow-1 font-weight-bold module-warning text-center h-100" v-html="helpers.getLocalizedField(note, 'text')"></div>
                  </div>
                </section>
                <!-- units -->
                <section class=" px-0 mx-0 px-xl-5 mx-xl-5 mb-5">
                    <div class="row justify-content-center">
                      <div v-if="loading" class="col-10 mb-5">
                        <sk-list :height="0.05" :items-count="1"></sk-list>
                      </div>
                      <div v-else class="col-10 mb-5">
                          <unit-item v-for="(unit, $index) of units"
                                     :key="$index"
                                     @key-vocabulary-click="showKeyVocabularyModal"
                                     :unit="unit"></unit-item>
                      </div>
                    </div>
                </section>
                <key-vocabulary-modal
                  @hide="onKeyVocabularyModalHide"
                  :unit="chosenUnit"></key-vocabulary-modal>
            </main>
        </div>
    </div>
</template>
<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import UnitItem from '../components/lab/unit-item';
import KeyVocabularyModal from '../components/lab/key-vocabulary-modal';
import LabResource from '../api/labResource';
import SkList from '../components/skeletons/sk-list';

const labResource = new LabResource();

export default {
    name:'my-applications',
    components: { KeyVocabularyModal, UnitItem, sidebar, appHeader, pageHeader, SkList },
    computed: {
      pageData() {
        return {
          title: this.$t('lab.title'),
            breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
          {
            icon: '',
            title: this.$t('lab.title'),
            link: '/lab',
            isActive: true,
          }],
        };
      },
    },

    data() {
        return {
          chosenUnit: null,
          loading: false,
          units: [],
          note: null
        }
    },

    mounted() {
      this.loadUnits();
      this.loadNote();
    },

  methods: {
    loadUnits() {
      this.loading = true;
      labResource.units().then(response => {
        this.units = response.data.data;
      })
        .finally(() => {
          this.loading = false;
        })
    },

    loadNote() {
      labResource.note().then(response => {
        this.note = response.data.data;
      })
    },

     showKeyVocabularyModal(unit) {
        this.chosenUnit = unit;
        this.$bvModal.show('key-vocabulary-modal');
     },

     onKeyVocabularyModalHide() {
        this.chosenUnit = null;
     }
  }
}
</script>
<style scoped>
.list  {
    font-size: 1.37rem;
    list-style-type: circle;
    margin-left: 2.5rem;
}
.list li{
    list-style: circle;
}
  .module-warning {
    font-size: 16px;
    color: #000;
  }
</style>
