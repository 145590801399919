import axios from 'axios';

export default class LabResource {
  units() {
    return axios.get('api/lab/units');
  }

  loadUnit(unitId) {
    return axios.get('api/lab/units/' + unitId);
  }

  sendRequest(unitId) {
    return axios.post('api/lab/units/' + unitId);
  }

  loadCurrentExercise(unitId) {
    return axios.get('api/lab/units/' + unitId + '/current-exercise');
  }

  loadNextExercise(unitId) {
    return axios.get('api/lab/units/' + unitId + '/next-exercise');
  }

  loadResults(unitId) {
    return axios.get('api/lab/units/' + unitId + '/results');
  }

  answer(unitId, exerciseId, answers) {
    return axios.post('api/lab/units/' + unitId + '/answer/' + exerciseId, {
      answers
    })
  }

  note() {
    return axios.get('api/lab/note');
  }
}
